
@import url(https://fonts.googleapis.com/earlyaccess/amiri.css);
/* font-family: 'Amiri', serif; */

@import url(https://fonts.googleapis.com/earlyaccess/droidarabicnaskh.css);
/*font-family: 'Droid Arabic Naskh', serif;*/

@import url(https://fonts.googleapis.com/earlyaccess/lateef.css);
/* font-family: 'Lateef', serif; */

@import url(https://fonts.googleapis.com/earlyaccess/scheherazade.css);
/*font-family: 'Scheherazade', serif;*/

@import url(https://fonts.googleapis.com/earlyaccess/thabit.css);
/* font-family: 'Thabit', serif; */

@import url("https://fonts.cdnfonts.com/css/handwriting");

@import url("https://fonts.googleapis.com/css2?family=Alexandria&family=Cairo:wght@200&family=Lemonada:wght@500&family=Noto+Sans+Arabic&family=Reem+Kufi:wght@400;500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Handjet:wght@100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Handjet:wght@100..900&family=Mada:wght@200..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Changa:wght@200..800&family=Handjet:wght@100..900&family=Mada:wght@200..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Handjet:wght@100..900&display=swap");




:root {
  --app-dark: #232f3e;
  --cbmis-light: #fefefe;
  --cbmis-primary: #0371a0;
  --cbmis-info: #01fefe /*#0496be */;
  --cbmis-secondary: #eaeff3;
  --orang-color: #ec9403;
  /* --cbmis-error: #ec9403; */
  --cbmis-input-color: #eaeff3;

  --app-h4-font-size: 33px;
  --app-h5-font-size: 23px;
  --app-h6-font-size: 21px;
  --app-subtitle1-font-size: 17px;
  --app-subtitle2-font-size: 15px;
  --app-body1-font-size: 17px;
  --app-body2-font-size: 15px;
  --app-caption-font-size: 13px;
}
/* div{
  margin: initial;
} */
body {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: var(--app-dark) !important;
  margin: 0 !important;
  background-color: var(--cbmis-light) !important;
  background-image: url(../old/assets/images/websiteBackground.png) !important;
  /* max-height: 90vh; */
  padding: 0;

  overflow-x: hidden !important;
  overflow-y: auto !important;
  font-family: "Thabit", "Scheherazade", adobe arabic, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
}

/* shared */
.cbmis-typography {
  text-align: justify !important;
  hyphens: auto;
  -webkit-hyphens: auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif !important; */
  white-space: normal !important;
}
.cbmis-typography-h4 {
  text-align: center !important;
  font-family: El Messiri, sans-serif !important;
  /* font-family: "Hand Font", "Reem Kufi", sans-serif !important; */

  font-weight: 600 !important;
  /* color: var(--cbmis-primary) !important; */
}

.cbmis-typography-subtitle1 {
  text-align: center !important;
}
.accordionTab_landingPage {
  align-self: start !important;
}
.accordionTab_landingPage .cbmis-accordion-tabs {
  border-radius: 0 0 8px 8px !important;
  -webkit-border-radius: 0 0 8px 8px !important;
  -moz-border-radius: 0 0 8px 8px !important;
  -ms-border-radius: 0 0 8px 8px !important;
  -o-border-radius: 0 0 8px 8px !important;
  box-shadow: none !important;
  background: transparent !important;
}
.accordionTab_landingPage .cbmis-accordion-tabs .cbmis-accordion-tab-summary {
  box-shadow: none !important;
}

/* header */
.cbmis-app-bar-fixed {
  position: absolute !important;
  color: var(--cbmis-primary) !important;
  background-color: transparent !important;
  box-shadow: none !important;
  top: 0 !important;
}

.cbmis-app-bar-Limiter {
  top: -1px !important;
  color: var(--cbmis-light) !important;
  background-color: var(--app-dark) !important;
  border: none !important;
  /* box-shadow: none !important; */
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.4);
}

.cbmis-drawer {
  background-color: var(--app-dark) !important;
  position: fixed !important;
  min-height: 100% !important;
  box-shadow: none !important;
  color: var(--cbmis-light) !important;
  z-index: 200000 !important;
  max-width: 75vw !important;
  min-width: 75vw !important;
  margin: 0 !important;
}

.cbmis-drawer .cbmis-app-bar-nav {
  border-bottom: 1px solid var(--cbmis-secondary) !important;
}

.cbmis-drawer-tollage {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  background-color: var(--app-dark) !important;
  opacity: 0.5 !important;
}

.cbmis-bottom-bar {
  background-color: var(--app-dark) !important;
  direction: rtl !important;
}
.cbmis-drawer .cbmis-groupBar::after {
  border-bottom: 0px solid transparent !important;
}

.cbmis-app-bar-nav .cbmis-app-bar-nav-sub-menu {
  background-color: var(--app-dark) !important;
  box-shadow: var(--cbmis-box-shadow-5) !important;
  border: 1px solid var(--cbmis-light) !important;
}
.cbmis-app-bar-nav .cbmis-app-bar-nav-sub-menu li {
  border-bottom: 1px solid var(--cbmis-light) !important;
  font-size: 18px !important;
}

.cbmis-drawer .cbmis-app-bar-nav .cbmis-app-bar-nav-sub-menu li span {
  padding-inline-start: 5px !important;
  font-size: 18px !important;
  text-align: start !important;
}
.cbmis-drawer .cbmis-app-bar-nav-content-content{
 margin: 0px 5px !important;
}

/* hero */
.heroVideo {
  position: absolute;
  height: 100% !important;
  margin: 0 !important;
  display: block !important;
  width: 100% !important;
  object-fit: cover !important;
}
/* MessageOfSheikhMuhammad */
.quoteEnd {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
/* our goal */
.boxView {
  width: 90% !important;
  border: 1px solid var(--cbmis-primary) !important;
  min-height: 200px !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  background-color: var(--cbmis-light) !important;
  box-shadow: 0 0 6px 0 #07456f !important;
}
.boxViewArb {
  width: 90% !important;
  min-height: 180px !important;
  border: 1px solid var(--cbmis-primary) !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  background-color: var(--cbmis-light) !important;
  box-shadow: 0 0 6px 0 #07456f !important;
}

.boxViewNoHeight {
  width: 100% !important;
  border: 1px solid var(--cbmis-primary) !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  background-color: var(--cbmis-light) !important;
  box-shadow: 0 0 6px 0 #07456f !important;
}
.hight200 {
  min-height: 300px !important;
}

.boxViewAvatar {
  width: 30px !important;
  height: 30px !important;
  background: var(--cbmis-secondary) !important;
  color: var(--cbmis-primary);
  border: 1px solid var(--cbmis-primary);
}

.cardView {
  min-height: 230px !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}
.cardView::before,
.cardViewArb::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 95px !important;
  display: block;
  background: var(--app-dark);
  opacity: 0.8;
  border-radius: 8px 9px;
}
.cardViewOpen::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100% !important;
  display: block;
  background: var(--app-dark);
  opacity: 0.8;
  border-radius: 8px 9px;
}
.cardViewArb {
  min-height: 190px !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}
.cardViewIconUp {
  /* position: absolute !important; */
  bottom: 5px !important;
}

@media screen and (max-width: 800px) {
  .boxView,
  .boxViewArb,
  .boxViewAvatar {
    min-height: auto !important;
  }
  .cardView {
    height: 270px !important;
  }
}

.cardViewAvatar {
  width: 30px !important;
  height: 30px !important;
  background: var(--app-dark) !important;
  color: var(--cbmis-primary);
  padding: 1px !important;
}

.cbmis-btn-contained label,
.cbmis-btn-contained .cbmis-typography {
  text-align: center !important;
}
.cbmis-btn-contained .cbmis-btn-label .cbmis-typography {
  text-align: center !important;
}

.CbmisIconContainer {
  width: 24px !important;
  height: 24px !important;
}
.cbmis-icon-container-in-btn .CbmisIconContainer {
  width: 21px !important;
  height: 21px !important;
}
.app-point .CbmisIconContainer {
  width: 14px !important;
  height: 14px !important;
}

.cbmis-icon {
  display: block !important;
  margin: auto !important;
}

.cardTeamImg {
  height: 225x !important;
}
.cardTeamDetails {
  position: absolute !important;
  right: 0 !important;
  bottom: -25px !important;
  width: 88% !important;
  overflow: hidden !important;
  background: var(var(--cbmis-secondary)) !important;
  color: var(--app-dark) !important;
  box-shadow: #999 0px 2px 24px !important;
  transition: all 0.3s !important;
  border-radius: 2px !important;
  padding: 5px !important;
  height: auto !important;
  z-index: 10 !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -ms-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
}

.cbmis-modal .cbmis-typography-subtitle1 {
  text-align: center !important;
}

.cbmisToTopButton {
  z-index: 10000 !important;
  border-color: var(--cbmis-primary) !important;
  color: var(--cbmis-primary) !important;
  background: #ffffffbb !important;
  bottom: 250px !important;
}
.cbmisToTopButton .CbmisIconContainer {
  color: var(--cbmis-primary) !important;
}

.arbLang {
  left: 30px !important;
  right: auto !important;
}

.cbmis-drawer-close {
  height: 60px !important;
}

.associationImg img {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  vertical-align: middle !important;
}
.associationImg::after {
  position: absolute !important;
  content: "" !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  background: url(../old/assets/images/bg-img.svg) top left no-repeat !important;
  background-size: contain !important;
}

a {
  color: var(--orang-color) !important;
}
.inline-ellipsis {
  display: block !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.inline-ellipsis:hover {
  display: block !important;
  display: -webkit-box !important;
  -webkit-line-clamp: initial !important;
  -webkit-box-orient: initial !important;
  overflow: auto !important;
  text-overflow: initial !important;
}

.block-ellipsis {
  display: block !important;
  display: -webkit-box !important;
  max-width: 100% !important;
  height: 123px !important;
  margin: 0 auto !important;
  font-size: var(--app-body1-font-size) !important;
  line-height: 1.5rem !important;
  -webkit-line-clamp: 5 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.block-ellipsis_title {
  display: block !important;
  display: -webkit-box !important;
  width: 100% !important;
  height: 50px !important;
  margin: 0 auto !important;
  font-size: var(--app-body1-font-size) !important;
  line-height: 1.5rem !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: center !important;
}

.inset {
  background-color: #0000004c;
  color: rgba(255, 255, 255, 0.1) !important;
  text-shadow: 1px 2px 2px var(--cbmis-primary), 0 0 0 #000, 1px 2px 2px var(--cbmis-primary) !important;
}

.eventImg {
  position: absolute !important;
  width: 92% !important;
  height: 300px !important;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.75);
  top: -170px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 8px !important ;
  -webkit-border-radius: 8px !important ;
  -moz-border-radius: 8px !important ;
  -ms-border-radius: 8px !important ;
  -o-border-radius: 8px !important ;
}

.event-start {
  justify-self: flex-start !important;
}
.event-end {
  justify-self: flex-end !important;
}

#footer .CbmisIconContainer {
  width: 26px !important;
  height: 26px !important;
}

.cbmis-conf-message-text {
  color: var(--app-dark) !important;
}

.menuFilter .navSubmenu {
  position: absolute !important;
  width: 160px !important;
  height: 0px !important;
  padding: 0 !important;
  z-index: 1000 !important;
  background: #fff !important;
  left: 15%;
  box-shadow: var(--cbmis-box-shadow-1) !important;
  border: 1px solid #ddd !important;
}
.navSubmenueng {
  right: 15% !important;
  left: initial !important;
}
@media only screen and (max-width: 600px) {
  .menuFilter .navSubmenu {
    /* left: 3vw; */
  }
}

.cbmis-tab-header-arrow {
  display: none !important;
  opacity: 0 !important;
}

.cbmis-tabs-container {
  width: 100% !important;
}

.cbmis-tabs {
  width: 97% !important;
  max-width: 97% !important;
}

.memberImg {
  border: 2px solid var(--cbmis-primary);
}

.loginIcon.CbmisIconContainer {
  width: 30px !important;
  height: 30px !important;
}

.cardContainer {
  background-color: #fff !important;
  border-bottom: 3px solid #07456f !important;
  border-bottom: 3px solid var(--cbmis-primary) !important;
  box-shadow: 0 0 10px 0 #07456f !important;
  box-shadow: 0 0 10px 0 var(--cbmis-primary) !important;
  color: #05304d !important;
  color: var(--primary-color) !important;
  text-align: justify !important;
}

.inputLeft {
  text-align: left !important;
  direction: ltr !important;
}
.inputLeftarb {
  padding-left: 0.65rem !important;
}

.cbmis-input-container {
  background: transparent !important;
}

.cbmis-input-label[data-placeholder]:before {
  background-image: linear-gradient(to bottom, var(--cbmis-white), var(--cbmis-white)) !important;
  z-index: 1 !important;
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* min-height: 0px !important; */
}

.cbmis-input-placeholder + .cbmis-input-label[data-placeholder],
.cbmis-input-classic + .cbmis-input-label[data-placeholder],
.cbmis-input-haveIcon .cbmis-input-placeholder + .cbmis-input-label[data-placeholder],
.cbmis-input-haveIcon .cbmis-input-classic + .cbmis-input-label[data-placeholder] {
  display: block;
  line-height: 1.7em;
  padding: 0px var(--cbmis-spacer-0);
  position: absolute;
  top: -1em;
  font-weight: var(--cbmis-font-weight-normal) !important;
  font-size: var(--app-body1-font-size);
  transform: translate(0, 0) scale(0.9, 0.9);
  margin: 0 15px;
  -webkit-transform: translate(0, 0) scale(0.9, 0.9);
  -moz-transform: translate(0, 0) scale(0.9, 0.9);
  -ms-transform: translate(0, 0) scale(0.9, 0.9);
  -o-transform: translate(0, 0) scale(0.9, 0.9);
  z-index: 1 !important;
}
.cbmis-input-haveIcon .cbmis-input-placeholder + .cbmis-input-label[data-placeholder] {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.cbmis-input-placeholder:focus + .cbmis-input-label[data-placeholder]:before,
.cbmis-input-haveIcon .cbmis-input-placeholder:focus + .cbmis-input-label[data-placeholder]:before {
  position: absolute !important;
  top: 4px !important;
}
.cbmis-input-label {
  font-weight: var(--cbmis-font-weight-normal) !important;
}

.cbmis-bottom-bar-element {
  height: 70px !important;
}

.cbmis-bottom-bar-element:hover,
.cbmis-bottom-bar-element-selected {
  color: var(--cbmis-primary) !important;
  padding: 0 !important;
  background-color: var(--cbmis-light) !important;
}

.cbmis-line-container .cbmis-line-Label {
  background: var(--cbmis-light) !important;
}
.cbmis-app-bar-hamburger {
  color: var(--cbmis-light) !important;
}

.cbmis-app-bar {
  max-height: 3.8rem !important;
  min-height: 60px !important;
}

.textcenter {
  text-align: center !important;
}

.textjustify {
  text-align: justify !important;
}
.textstart {
  text-align: start !important;
}
.cbmis-input-haveIcon .cbmis-input-placeholder:placeholder-shown + .cbmis-input-label[data-placeholder],
.cbmis-input-haveIcon .cbmis-input-classic:placeholder-shown + .cbmis-input-label[data-placeholder] {
  z-index: 1 !important;
}

.cbmis-slideshow {
  border-radius: 8px !important;
}
.cbmis-slide {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  min-height: 275px !important;
}
.height300 .cbmis-slide {
  min-height: 250px !important;
}
.height400 .cbmis-slide {
  min-height: 350px !important;
}

.cbmis-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  background-color: #000;
  height: 100%;
  display: block;
  opacity: 0.3;
}

.cbmis-slideshow-btn-slide {
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  background-color: transparent !important;
  color: var(--cbmis-primary) !important;
}

.cbmis-slideshow-btn-slide::before {
  background-color: transparent;
}

.lineUp {
  animation: 2s anim-lineUp;
  -webkit-animation: 2s anim-lineUp;
  color: var(--cbmis-light) !important;
}

/* .lineUp1 { animation-delay: 0s }
.lineUp2 { animation-delay: 4s }
.lineUp3{ animation-delay: 7s }
.lineUp4 { animation-delay: 10s }
.lineUp5 { animation-delay: 13s } */

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* @media only screen and (max-width: 760px) {
  .cbmis-slide{
    min-height: 0px !important
  }
} */

.hightFull {
  min-height: 100% !important;
}

.fontHand {
  font-family: "Hand Font", "Reem Kufi", sans-serif !important;
}

.primaryText {
  color: var(--app-dark) !important;
  background: #fefefe;
  padding: 0 !important;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
}

.cbmis-typography-h4 {
  font-size: var(--app-h4-font-size) !important;
}
.cbmis-typography-h5 {
  font-size: var(--app-h5-font-size) !important;
}
.cbmis-typography-h6 {
  font-size: var(--app-h6-font-size) !important;
}
.cbmis-typography-caption {
  font-size: var(--app-caption-font-size) !important;
}

.cbmis-typography-body1 {
  font-size: var(--app-body1-font-size) !important;
}
.cbmis-typography-body2 {
  font-size: var(--app-body2-font-size) !important;
}

.cbmis-typography-subtitle1 {
  font-size: var(--app-subtitle1-font-size) !important;
  font-weight: var(--cbmis-font-weight-bold);
}

.cbmis-typography-subtitle2 {
  font-size: var(--app-subtitle2-font-size) !important;
  font-weight: var(--cbmis-font-weight-bold);
}

@media only screen and (max-width: 600px) {
  .cbmis-typography-h4 {
    font-size: var(--app-h4-font-size) !important;
  }
  .cbmis-typography-h5 {
    font-size: var(--app-h5-font-size) !important;
  }
  .cbmis-typography-h6 {
    font-size: var(--app-h6-font-size) !important;
  }
  .cbmis-typography-caption {
    font-size: var(--app-caption-font-size) !important;
  }

  .cbmis-typography-body1 {
    font-size: var(--app-body2-font-size) !important;
  }
  .cbmis-typography-body2 {
    font-size: var(--app-body2-font-size) !important;
  }

  .cbmis-typography-subtitle1 {
    font-size: var(--app-subtitle1-font-size) !important;
    font-weight: var(--cbmis-font-weight-bold);
  }

  .cbmis-typography-subtitle2 {
    font-size: var(--app-subtitle2-font-size) !important;
    font-weight: var(--cbmis-font-weight-bold);
  }
}

/* CSS */

/* CSS */
.button-10 {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: var(--cbmis-primary) !important;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember", sans-serif;
  font-size: var(--app-caption-font-size);
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  min-width: 100px;
  position: relative;
}

.button-10:hover {
  background-color: #f7fafa;
}

.button-10:focus {
  border-color: var(--cbmis-secondary);
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  outline: 0;
}

.button-10 img {
  position: relative;
  top: 3px !important;
}

.fontMd .CbmisIconContainer {
  width: 28px !important;
  height: 28px !important;
}

.fontLg .CbmisIconContainer {
  width: 32px !important;
  height: 32px !important;
}

.fontXL .CbmisIconContainer {
  width: 36px !important;
  height: 36px !important;
}

.App .cbmis-input-icon-container,
.App .cbmis-input-adornment-container,
.App .cbmis-input-icon-end-container,
.App .cbmis-input-endAdornment-container {
  z-index: 100 !important;
  padding: 0 5px !important;
}

/* ******************************************************account************************************************************** */

.navAccount .cbmis-app-bar-nav {
  width: 80% !important;
}

.emailView .cbmis-input-container {
  background-color: transparent !important;
  direction: ltr !important;
}
.emailView > * {
  direction: ltr !important;
}
.emailView .cbmis-checkbox-container-block-display {
  margin: 5px !important;
}

.emailView .cbmis-menu-item-content {
  margin: 0 !important;
  width: 100% !important;
}
.file .cbmis-btn-label,
.file .cbmis-icon {
  color: var(--cbmis-primary) !important;
  fill: var(--cbmis-primary) !important;
}

.textareaeng .cbmis-input-icon-end-container,
.textareaeng .cbmis-input-endAdornment-container {
  margin: auto 0 48% 0 !important;
}
.cbmis-badge-content {
  background-color: var(--cbmis-error) !important;
}

.boxNav {
  width: 100% !important;
  cursor: pointer;
}

.boxNav:hover {
  background-color: #eee !important;
}

.cbmis-menu-item-content,
.cbmis-menu-item-head-content {
  margin: 0 !important;
  width: 100% !important;
}

.cbmis-alert-note-primary {
  background-color: #d7e9f7 !important;
  color: var(--cbmis-primary) !important;
}

.activeLabel {
  width: 65% !important;
  text-align: center !important;
}

.cbmis-checkbox-container {
  margin: var(--cbmis-spacer-2) !important;
}

.partnerPaper::before {
  background: transparent !important;
}

.cbmis-glass {
  position: relative !important;
}
.cbmis-glass:before {
  box-shadow: none !important;
  background-color: #000 !important;
  opacity: 0.8 !important;
}

.cardArc > path {
  fill: #fff;
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.cbmis-accordion-col {
  max-width: 100% !important;
}

.cbmis-accordion-tab-summary-label-content {
  max-width: 90% !important;
}

/* .serv-card-account .cardOverlay , .serv-card-account .cardHeader {
  z-index: 1 !important;
} */
.serv-card-account .cbmis-typography {
  text-align: justify !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.cardOverlayHovered,
.cardOverlayHovered .cardHeader {
  transform: translateY(0) !important;
  -webkit-transform: translateY(0) !important;
  -moz-transform: translateY(0) !important;
  -ms-transform: translateY(0) !important;
  -o-transform: translateY(0) !important;
}

.servBgColor {
  background: linear-gradient(45deg, #cee1ea, #0371a0) !important;
}

.account-slideshow .cbmis-slide {
  background-color: #fff !important;
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
  max-height: 250px !important;
  min-height: 250px !important;
}
.account-slideshow .cbmis-slide::before {
  background-color: #fff !important;
}

.cbmis-slideshow-btn-slide::before {
  background: transparent !important;
}

@media screen and (max-width: 900px) {
  .account-slideshow .cbmis-slide {
    max-height: 400px !important;
    min-height: 400px !important;
  }
}

@media screen and (max-width: 600px) {
  .account-slideshow .cbmis-slide {
    max-height: 450px !important;
    min-height: 450px !important;
  }
}

.traineeName {
  font-weight: 500 !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.traineeName:hover {
  text-overflow: initial !important;
  white-space: initial !important;
  overflow: initial !important;
}

.cbmis-accordion-tab-summary-label-content-children .cbmis-typography {
  text-align: start !important;
}

@media only screen and (max-width: 600px) {
  .cbmis-box-mark-radio,
  .cbmis-box-mark-check {
    margin-inline-end: 5px !important;
  }
  .cbmis-option-input-label {
    font-size: var(--app-caption-font-size) !important;
    line-height: normal !important;
  }
}

.cbmis-input-haveIcon .cbmis-input-placeholder + .cbmis-input-label[data-placeholder],
.cbmis-input-haveIcon .cbmis-input-classic + .cbmis-input-label[data-placeholder] {
  padding: 0px var(--cbmis-spacer-1) !important;
  font-weight: 400 !important;
}

.customNumber .CbmisIconContainer {
  max-width: 16px !important;
  max-height: 16px !important;
  font-size: 16px !important;
}

.textCenter {
  text-align: center !important;
}

.cbmis-accordion-tab-summary-label-content {
  max-width: 98% !important;
}

@media screen and (max-width: 768px) {
  .cbmis-table-contenter .cbmis-table .cbmis-table-cell {
    display: flex !important;
    justify-content: space-between !important;
  }
  .cbmis-table-contenter .cbmis-table .cbmis-table-cell::before {
    position: relative !important;
    display: flex !important;
  }
  .cbmis-table-contenter .cbmis-table .cbmis-table-cell .cbmis-typography-body2 {
    display: contents !important;
  }

  .radioMode2 .cbmis-radio-bottons-container {
    margin: 10px auto !important;
  }

  .radioMode2 .cbmis-radio-bottons-container .cbmis-input-botton-radio {
    z-index: 10 !important;
  }

  .radioMode2 .cbmis-box-mark-radio {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 3px !important;
    z-index: 1 !important;
  }

  .radioMode2 .cbmis-option-input-label {
    z-index: 2 !important;
    padding: 3px !important;
  }

  .radioMode2 .cbmis-radio-bottons-container .cbmis-radio-colored::after {
    background-color: transparent !important;
  }
}

.cbmis-tab-container {
  min-width: fit-content !important;
  margin: 0 7px !important;
}

.cbmis-tabs-container-mode-box .cbmis-tab {
  display: flex !important;
  justify-content: center !important;
}

.cbmis-input-container {
  width: 100% !important;
}

.cbmis-input-label {
  font-size: var(--app-body2-font-size) !important;
}

.cbmis-input-icon-end-lang-list {
  padding: 0 !important;
  width: 35px !important;
  display: block !important;
}
.cbmis-input-icon-end-lang-list .cbmis-typography-caption {
  font-size: 12px !important;
}
.cbmis-input-icon-end-lang-list .CbmisIconContainer {
  width: 15px !important;
  height: 15px !important;
  position: relative;
}

.cbmis-accordion-tab-summary .cbmis-accordion-tab-summary-label::after {
  position: relative !important;
  margin: auto 5px !important;
}

.cbmis-body-dir-rtl .cbmis-accordion-tab-summary .cbmis-accordion-tab-summary-label::after,
.cbmis-dir-rtl .cbmis-accordion-tab-summary .cbmis-accordion-tab-summary-label::after {
  transform: rotate(270deg) translateY(0%) !important;
}

.cbmis-accordion-tab-summary-input:checked + .cbmis-accordion-tab-summary-label::after {
  transform: rotate(90deg) translateY(0%) !important;
}

.cbmis-tooltip {
  z-index: 10 !important;
  position: absolute !important;
  background: transparent !important;
  width: 40% !important;
  height: 40% !important;
}

.cbmis-tooltip .cbmis-tooltip-text {
  background-color: #eee !important;
  color: #222 !important;
  opacity: 1 !important;
}

.cbmis-tooltip .cbmis-tooltip-text-position-top,
.cbmis-tooltip .cbmis-tooltip-text-position-top-arrow {
  margin: var(--cbmis-spacer-0) 80px !important;
  min-width: 150px !important;
}

.videoTraining {
  position: relative;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  max-height: 550px;
}

.hightFit {
  height: fit-content !important;
}

.activity_targeted .cbmis-input-container,
.activity_targeted div {
  /* width: fit-content !important; */
  margin: 0 !important;
}

.activity_targeted .cbmis-option-input-label {
  font-weight: 500 !important;
}

.advModal {
  background-color: var(--app-dark) !important;
}

.cbmis-app-bar-nav .cbmis-app-bar-nav-content {
  padding: 7px 0 !important;
}
/* -------------------------------------------------------------- */

.cbmis-input-container > div:first-child {
  margin: auto 0px !important;
}
.cbmis-radio-bottons-container-block-display > div:first-child {
  margin: auto 0px !important;
}
.cbmis-checkbox-multipleList-container > div:first-child {
  margin: auto 0px !important;
}

.border-line {
  border-inline-end: 1px dashed var(--cbmis-primary) !important;
  border-radius: 0;
}

.textjustifyCener {
  text-align: center !important;
}

.darkContainer {
  /* background: var(--cbmis-light) !important; */
  /* background-image: url(../old/assets/images/ai.jpg) !important;
  background-attachment: fixed;
  background-size: 100% 100% !important; */
  border: 1px solid var(--cbmis-primary) !important;
  background: linear-gradient(315deg, rgba(2, 0, 36, 1) 0%, rgba(35, 47, 62, 1) 60%, rgba(3, 113, 160, 1) 100%) !important;
}

.darkContainer * {
  color: var(--cbmis-light) !important;
}
.darkContainer svg {
  fill: var(--cbmis-primary) !important;
}

.cbmis-drawer .cbmis-drawer-close {
  width: 100% !important;
}

.avatar {
  width: 50px !important;
  height: 49px !important;
}

@media screen and (max-width: 600px) {
  .border-line {
    border-inline-end: none !important;
    border-block-end: 1px dashed var(--cbmis-primary) !important;
  }

  .border-line1 {
    border-inline-end: none !important;
    border-block-end: 1px dashed var(--cbmis-primary) !important;
  }
  .textjustifyCener {
    text-align: justify !important;
  }
}
.cbmis-alert-message-text-content {
  line-height: normal !important;
}

.cbmis-checkbox-container > div {
  margin: 0 !important;
}
.border-radius {
  border-radius: 50% !important;
  border-color: var(--cbmis-primary) !important;
}

.descriptionText5 {
  line-clamp: 7 !important;
  -webkit-line-clamp: 7 !important;
  height: calc(7 * 1.5em) !important;
  box-orient: vertical !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  width: 100% !important;
  text-align: justify !important;
  cursor: pointer !important;
  user-select: none !important;
}

.descriptionText5:hover{
  line-clamp: inherit !important;
  -webkit-line-clamp: inherit !important;
  height: auto !important;
  overflow: auto !important;

}
.arrowTopClose{
  margin-top: 10px !important;
  display: none !important;
}

.descriptionText5:hover + .arrowTopClose {
    display: block !important;
}

