
.uicore_error_message_154bcfp{
  color: var(--cbmis-error) !important;
}


.uicore_radio:checked + .uicore_radio_opt_label{
  background-color: #07456f !important;
  color: #eaeff3 !important;
}


.uicore_container_sx_1o7hb2k {
  padding: 0 !important;
}

.uicore_switch_container{
  width: auto !important;
  max-width: 100% !important;
}


/* 
.uicore_checkbox{
  top: 1.1rem !important;
} */

.uicore_checkbox_opt_label{
  font-size: medium;
  padding: 5px 10px 0px ;
}

.uicore_stepper_head::after{
  content: "" !important;
  max-height: 1px !important;
}
.uicore_stepper_step{
  background: #011C35;
  opacity: 1;
}
.uicore_stepper_head::after{
  z-index: -1;
  top : 37%  !important
}
@media screen and (max-width: 600px) {
  .uicore_container_sx_1s36ld9 {
      padding: 0 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: currentColor !important;
  transition: all 9999s ease-in-out 0s !important;
  /* transition: color 9999s ease-in-out 0s !important; */
  -webkit-text-fill-color: currentColor !important;
}


/* ------------------------------------------------------------ */
#root{
  height: 100%;
  padding: 0;
  margin: 0;
}
.App{
  height: 100%;
  padding: 0;
  margin: 0;
}
.uicore_app_bar{
  position: fixed;
  height: 3rem !important;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);}


/* .descriptionLightText:hover {
  overflow: visible;
  height: auto;
  line-clamp: inherit;
  -webkit-line-clamp: initial;
  }

  /* .descriptionLightText:hover{
    line-clamp: unset;
    -webkit-line-clamp: unset;
    height: auto;
     box-orient: vertical;
    -webkit-box-orient: vertical; 
    overflow: hidden;
    display: block;
  } */ 

  .uicore_typography{
    text-align: justify !important;
  }

  .paddingBottom{
    padding-bottom: 0px !important;
  }

  .uicore_fieldset_input{
    resize: none !important;
  }
  .uicore_fieldset_label{
    inset: 5px 0px 0px !important;
  }

  @keyframes darken {
    0%{ font-size: 40px;  color:rgb(2 254 254 / 10%);}
    25%{font-size: 80px; color:rgb(2 254 254 / 25%)}
    50%{font-size: 120px; color:rgb(2 254 254 / 50%)}
    75%{font-size: 160px; color:rgb(2 254 254 / 75%)}
    100% {font-size: 200px; color:rgb(2 254 254)}
  }


    .canvasContiner{
      min-height: 100%;
      min-width: 100%;
      text-align: center;
    }

    .textAligncenter{
      text-align: center !important;
    }

    .scroll-prompt {
      position: fixed;
      z-index: 998;
      bottom: -80px;
      left: 50%;
      margin-left: -80px;
      width: 160px;
      height: 160px;
    
      
    }
    .scroll-prompt-arrow-container {
      /* position: absolute;
      top: 0;
      left: 50%;
      margin-left: -18px; */
      animation-name: bounce;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
    .scroll-prompt-arrow {
      animation-name: opacity;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
    .scroll-prompt-arrow:last-child {
      animation-direction: reverse;
      margin-top: -6px;
    }

     .arrow {
      /* position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform: rotate(90deg);
      cursor: pointer; */
      position: relative;
      margin: auto;
      height: 70px;
      width: 20px;
      /* transform: translateX(-50%,); */
  } 
  
  .arrow span {
      display: block;
      width: 20px;
      height: 20px;
      border-bottom: 5px solid #02FEFE;
      border-right: 5px solid #02FEFE;
      transform: rotate(45deg);
      margin: -10px;
      animation: animate 2s infinite;
  }
  
  .arrow span:nth-child(2) {
      animation-delay: -0.2s;
  }
  
  .arrow span:nth-child(3) {
      animation-delay: -0.4s;
  }
  
  @keyframes animate {
      0% {
          opacity: 0;
          transform: rotate(45deg) translate(-20px, -20px);
      }
      50% {
          opacity: 1;
      }
      100% {
          opacity: 0;
          transform: rotate(45deg) translate(20px, 20px);
      }
  }

  .uicore_modal_close_btn{
    z-index: 1 !important;
  }

  .uicore_nav_item:hover{
    background: rgb(2 254 254 / 10%) !important;
    border-radius: 4px;
  }
  .uicore_menu{
    padding: 5px !important;
    background-color: #011C35 !important;
    opacity: 1;
  }
  .uicore_menu_container{
    min-width: 90px !important;
    padding: 0 !important;
  }