
  .rootAdvisorCard {
    position: relative;
    width: 350px;
    min-height: 580px;
    margin-top: 5px;
    padding-top: 0px!important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-image: url("../../../../../assets/images/emp.jpg");
    background-repeat: no-repeat;
    background-size: 350px 580px;
    text-align: center;
  }
  .rootAdvisorCard .AllContantAdvisorCard {
    margin-top: 70px;
  }
  .divCardAdvisorCard {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .divCardAdvisorCard .containerAdvisorCard {
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
  }
  .divAvatar {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-bottom: 0;
  }
  .divAvatar .largeAvatar {
    width: 110px;
    height: 110px;
    border: 5px solid #366db7;
    border-radius: 10px !important;
    margin-top: -25px;
  }
  .containerAdvisorCard .cardHeader {
    padding-left: 25px;
    color: #366db7;
  }
  .cardHeader .titleHeader {
    font-weight: bolder;
    text-align: center;
    font-size: 16px;
  }
  .cardHeader .subheaderHeader {
    color: #366db7;
    font-size: 14px;
    text-align: center;
  }
  .divCardAdvisorCard .activeBtn {
    background-color: #7dc349;
    /* margin-left: 65px; */
    width: 150px;
    color: white;
    padding: 5px;
    border-radius: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .divCardAdvisorCard .activeBtn:hover {
    background-color: #366db7;
  }
  
  .divCardAdvisorCard .containerAdvisorCard .cardContant .cardContantText {
    text-align:right;
  }
  .EmployeeName .name {
    color: rgb(63, 63, 63);
    font-size: 15px;
    font-weight: 900;
    text-align: center !important;
  }
  .cardContant .cardContantText .positionName {
    color: #4286c4 !important;
    font-size: 16px;
  }
  .cardContant .cardContantText .countryAndDate {
    margin-left: 0px;
    text-align: start !important;
    font-size: 10px;
  }


  .cardContant .cardContantText .countryAndDate span {
    font-size: 12px;
    font-weight: normal;
    margin-left: 60px;
    color: #4d4d4d;
  }
  
  .image {
    width: 80px;
    height: 72px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .divCbmisLink {
    margin-top: 5px;
  }
  .divCbmisLink .cbmisLink {
    text-decoration: none;
    color: white;
    font-size: 25px;
  }
  
  @media screen and (max-width: 320px) {
    .rootAdvisorCard {
      width: 320px;
      min-height: 568px;
      margin: 0;
      background-size: 320px 568px;
    }
    .rootAdvisorCard .AllContantAdvisorCard {
      margin-top: 70px;
    }
    .divCardAdvisorCard {
      height: 100%;
      width: 100%;
    }
    .divCardAdvisorCard .containerAdvisorCard {
      padding: 0px;
      margin: 0px;
    }
    .containerAdvisorCard .cardHeader {
      padding-left: 15px;
    }
    .EmployeeName .name {
      font-size: 14px;
    }
    .cardContant .cardContantText .positionName {
      font-size: 15px;
    }
    .divCardAdvisorCard .activeBtn {
      align-items: center;
      width: 130px;
      margin-top: 10px;
    }
    .cardContant .cardContantText .countryAndDate {
      text-align: left;
      align-items: center;
    }
  
    .cardContant .cardContantText .countryAndDate span {
      font-size: 12px;
      font-weight: normal;
      margin-left: 68px;
    }
    .divCbmisLink {
      margin-top: 20px;
    }
    .divCbmisLink .cbmisLink {
      font-size: 25px;
    }
  }
  
  @media only screen and (min-device-width: 411px) and (max-device-width: 414px) {
    .rootAdvisorCard {
      width: 411px;
      min-height: 731px;
      margin: 0;
      background-size: 411px 731px;
    }
    .rootAdvisorCard .AllContantAdvisorCard {
      margin-top: 120px;
    }
    .divCardAdvisorCard .containerAdvisorCard {
      padding: 0px;
      margin: 0px;
    }
    .containerAdvisorCard .cardHeader {
      padding-left: 19px;
    }
    .divCardAdvisorCard .activeBtn {
      margin-left: 0px;
      width: 150px;
      margin-top: 10px;
      height: 40px;
      font-size: 20px;
    }
    .cardContant .cardContantText .countryAndDate {
      font-size: 17px;
    }
  
    .cardContant .cardContantText .countryAndDate span {
      font-size: 17px;
      margin-left: 80px;
      padding: 15px;
    }
    .divCardAdvisorCard .containerAdvisorCard .cardContant .cardContantText {
      text-align: center;
    }
    .EmployeeName .name {
      font-size: 17.5px;
      font-weight: 900;
    }
    .cardContant .cardContantText .positionName {
      font-size: 17px;
      padding-bottom: 10px;
    }
  
    .image {
      width: 110px;
      height: 105px;
    }
    .cardHeader .titleHeader {
      font-size: 20px;
    }
    .cardHeader .subheaderHeader {
      font-size: 18px;
      text-align: center;
    }
    .divAvatar .largeAvatar {
      width: 115px;
      height: 115px;
      margin-top: -20px;
    }
    .divCbmisLink {
      margin-top: 30px;
    }
    .divCbmisLink .cbmisLink {
      font-size: 30px;
    }
  }
  @media screen and (width: 414px) {
    .rootAdvisorCard {
      width: 414px;
      min-height: 736px;
      background-size: 414px 736px;
    }
    .divCardAdvisorCard .containerAdvisorCard {
      padding: 0px;
      margin: 0px;
    }
  }
  @media screen and (device-width: 411px) and (device-height: 823px) {
    .rootAdvisorCard {
      width: 411px;
      min-height: 823px;
      background-size: 414px 823px;
    }
    .rootAdvisorCard .AllContantAdvisorCard {
      margin-top: 130px;
    }
    .containerAdvisorCard .cardHeader {
      margin-bottom: 10px;
      padding-left: 19px;
    }
    .image {
      width: 120px;
      height: 120px;
    }
    .cardHeader .subheaderHeader {
      margin-bottom: 10px;
    }
    .EmployeeName .name {
      font-size: 18px;
    }
    .cardContant .cardContantText .positionName {
      font-size: 17px;
      padding-top: 10px;
      padding-bottom: 20px;
    }
    .cardContant .cardContantText .countryAndDate span {
      font-size: 17px;
      margin-left: 90px;
      padding: 15px;
    }
    .divCardAdvisorCard .activeBtn {
      margin-top: 30px;
    }
  }
  
  @media only screen and (device-width: 375px) and (device-height: 812px) {
    .rootAdvisorCard {
      width: 375px;
      min-height: 812px;
      margin: 0;
      background-size: 375px 812px;
    }
    .rootAdvisorCard .AllContantAdvisorCard {
      margin-top: 138px;
    }
  
    .divCardAdvisorCard .containerAdvisorCard {
      padding: 0px;
      margin: 0px;
    }
    .divAvatar .largeAvatar {
      width: 110px;
      height: 110px;
      margin-top: -20px;
    }
    .containerAdvisorCard .cardHeader {
      margin-top: -25px;
      margin-bottom: 20px;
      padding-left: 15px;
    }
    .cardHeader .titleHeader {
      font-size: 19px;
      margin-top: 15px;
    }
    .cardHeader .subheaderHeader {
      font-size: 17px;
    }
  
    .divCardAdvisorCard .activeBtn {
      margin-left: 0px;
      width: 150px;
      margin-top: 30px;
      margin-bottom: 20px;
      height: 35px;
      font-size: 18px;
    }
    .EmployeeName .name {
      font-size: 16px;
    }
    .cardContant .cardContantText .positionName {
      font-size: 17px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .cardContant .cardContantText .countryAndDate span {
      font-size: 15px;
      margin-left: 85px;
      padding: 10px;
    }
    .image {
      width: 115px;
      height: 115px;
    }
    .divCbmisLink {
      margin-top: 35px;
    }
    .divCbmisLink .cbmisLink {
      font-size: 30px;
    }
  }
  @media only screen and (device-width: 375px) and (device-height: 667px) {
    .rootAdvisorCard {
      width: 375px;
      min-height: 667px;
      margin: 0;
      background-size: 375px 667px;
    }
    .rootAdvisorCard .AllContantAdvisorCard {
      margin-top: 100px;
    }
  
    .divCardAdvisorCard .containerAdvisorCard {
      padding: 0px;
      margin: 0px;
    }
    .divAvatar .largeAvatar {
      width: 110px;
      height: 110px;
      margin-top: -20px;
    }
    .containerAdvisorCard .cardHeader {
      margin-top: -20px;
      margin-bottom: 20px;
      padding-left: 15px;
    }
    .cardHeader .titleHeader {
      font-size: 19px;
      margin-top: 10px;
    }
    .cardHeader .subheaderHeader {
      font-size: 15px;
    }
  
    .divCardAdvisorCard .activeBtn {
      margin-left: 0px;
      width: 150px;
      margin-top: 10px;
      height: 35px;
      font-size: 18px;
    }
  
    .cardContant .cardContantText .countryAndDate span {
      font-size: 15px;
      margin-left: 80px;
      padding: 10px;
    }
    .EmployeeName .name {
      font-size: 16px;
    }
    .cardContant .cardContantText .positionName {
      font-size: 16px;
      padding-bottom: 10px;
    }
  
    .image {
      width: 88px;
      height: 88px;
    }
    .divCbmisLink {
      margin-top: 15px;
    }
  
    .divCbmisLink .cbmisLink {
      font-size: 30px;
    }
  }
  
  @media screen and (max-width: 280px) {
    .rootAdvisorCard {
      width: 280px;
      min-height: 653px;
      margin: 0;
      background-size: 280px 653px;
    }
    .rootAdvisorCard .AllContantAdvisorCard {
      margin-top: 83px;
    }
    .containerAdvisorCard .cardHeader {
      padding-left: 18px;
      margin-bottom: 10px;
    }
    .divCardAdvisorCard .activeBtn {
      margin-left: 0px;
      width: 130px;
      margin-top: 15px;
      height: 30px;
      font-size: 15px;
    }
    .cardContant .cardContantText .countryAndDate {
      font-size: 17px;
    }
  
    .cardContant .cardContantText .countryAndDate span {
      font-size: 14px;
      margin-left: 30px;
      padding: 15px;
    }
    .divCardAdvisorCard .containerAdvisorCard .cardContant .cardContantText {
      text-align: center;
    }
    .EmployeeName .name {
      font-size: 12.25px;
    }
    .cardContant .cardContantText .positionName {
      font-size: 13px;
      padding-bottom: 10px;
    }
    .image {
      width: 90px;
      height: 90px;
    }
    .cardHeader .titleHeader {
      font-size: 15px;
      margin-top: 10px;
    }
    .cardHeader .subheaderHeader {
      font-size: 16px;
      text-align: center;
    }
    .divAvatar .largeAvatar {
      width: 87px;
      height: 87px;
      margin-bottom: 0px;
    }
    .divCbmisLink {
      margin-top: 45px;
    }
    .divCbmisLink .cbmisLink {
      font-size: 25px;
    }
  }
  
  @media screen and (min-width: 360px) and (max-width: 360px) {
    .rootAdvisorCard {
      width: 360px;
      min-height: 640px;
      margin: 0px;
      background-size: 360px 640px;
    }
    .rootAdvisorCard .AllContantAdvisorCard {
      margin-top: 90px;
    }
    .divCardAdvisorCard .containerAdvisorCard {
      padding: 0px;
      margin: 0px;
    }
    .containerAdvisorCard .cardHeader {
      padding-left: 18px;
    }
    .divCardAdvisorCard .activeBtn {
      margin-left: 0px;
      width: 140px;
      margin-top: 10px;
      height: 35px;
      font-size: 17px;
    }
    .cardContant .cardContantText .countryAndDate {
      font-size: 17px;
    }
  
    .cardContant .cardContantText .countryAndDate span {
      font-size: 17px;
      margin-left: 60px;
      padding: 15px;
    }
    .divCardAdvisorCard .containerAdvisorCard .cardContant .cardContantText {
      text-align: center;
    }
    .EmployeeName .name {
      font-size: 15.5px;
      font-weight: 900;
    }
    .cardContant .cardContantText .positionName {
      font-size: 17px;
      padding-bottom: 10px;
    }
    .image {
      width: 90px;
      height: 85px;
    }
    .cardHeader .titleHeader {
      font-size: 18px;
    }
    .cardHeader .subheaderHeader {
      font-size: 16px;
      text-align: center;
    }
    .divAvatar .largeAvatar {
      width: 100px;
      height: 100px;
      margin-top: -20px;
    }
    .divCbmisLink {
      margin-top: 20px;
    }
    .divCbmisLink .cbmisLink {
      font-size: 27px;
    }
  }
  
  .activeLabel{
     background-color: #7dc349;
    width: 150px;
    color: white;
    padding: 3px;
    border-radius: 18px;
  }